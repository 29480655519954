@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --orange: #ff8139;
  --pink: #ff3998;
  --green: #39ffa0;
  --red: #ff3939;
  --blue: #404da3;
  --light-green: #edfff6;
  --light-orange: #fff0e8;
  --accent-color1: #ffffff;
  --accent-color2: #555555;
  --accent-color3: #cccccc;
  --card-color: #000000;
  --background: #111111;
  --font-family: "BlackChancery"
}

@font-face {
  font-family: "BlackChancery";
  src: url(../../font/font.TTF);
  font-weight: normal;
  font-style: normal;
}

[data-theme="light"] {
  --background: #ffffff;
  --accent-color1: #000000;
  --accent-color2: #eeeeee;
  --accent-color3: #777777;
  --card-color: #eeeeee;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;


}
a:-webkit-any-link {
  color: white;
  cursor: pointer;
  text-decoration: none;
  transition: all 2s;
}
a:-webkit-any-link:hover {
  // background-color: red;
}
html {
  scroll-behavior: smooth;
  background-color: #000000;

}
.bg-container {
  position: relative;
  
  height: 100vh;
  font-family: var(--font-family);
  overflow-x: hidden;
  transition: 0.5s ease-in-out;
}

.bg-overlay {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0); /* Adjust the opacity as needed */
  opacity: 0.6;
  background-image: url('../../assets/super1.jpg');
  background-size: cover;
  background-position: center;
  animation: fade-out linear forwards;
  animation-timeline: view(100px 100vh);
  
}
.bg2 {
position:relative;
}
.bg2::before {
  content: ""; /* Necessary for pseudo-elements */
  position: absolute; /* Position the pseudo-element */
  top: 0; /* Adjust positioning if necessary */
  left: 0; /* Adjust positioning if necessary */
  width: 100%; /* Cover the entire element */
  height: 100%; /* Cover the entire element */
  min-height: 100vh;
  background-image: url('../../assets/super1.jpg');
  background-size: cover;
  background-position: center;
  opacity: 0.5; /* Adjust opacity as needed */
}
// @media screen and (min-width: 280px) and (max-width: 1080px) {
//   .bg-container {
//     background-image:url('../../assets/super1.jpg');
//     background-size: cover;
//     background-position: center;
//     opacity: 0.8;
//     padding: 0;
//   }
      
// }


@keyframes fade-out {
90%, 100% {opacity: 0;}
}

// .app-container {
//   // background-color: var(--background);
//   font-family: var(--font-family);
//   overflow-x: hidden;
//   transition: 0.5s ease-in-out;
//   padding: 2rem 4rem;
//   overflow: hidden;
//   @media screen and (min-width: 280px) and (max-width: 1080px) {
//     padding: 0;
//   }
// }

@media only screen and (min-device-width: 280px) and (max-device-width: 1080px) {
  // .app-container::-webkit-scrollbar {
  //   display: none;
  // }
  // .bg-container::-webkit-scrollbar {
  //   display: none;
  // }
}

::-webkit-scrollbar {
  background-color: #a13737;
  width: 0rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--orange);
}
