.mobile-only {
  display: none;
}
.title-mobile {
  display: none;
}
.lore {
  border-top: 0.5rem solid purple;
  // border-bottom: 0.1rem solid white;
  overflow-y: hidden;  
  // margin-top: 25rem;
  border-radius: 2rem;
  border-top-style:ridge;
  opacity: 0;
  animation: fade-in linear forwards;
  animation-timeline: view(150px);
  animation-range-start: cover;
  animation-range-end: contain;
  

  .container {

    display: grid;
    // grid-template-columns: 1fr 1fr;
    font-size: 18px;
    
    .content {
      margin-top: 5rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items:center;
      .sub-title {
        color: var(--pink);
        text-transform: uppercase;
        letter-spacing: 0.1rem;
      }
      .title {
        color: var(--accent-color1);
      }
      .description {
        color: var(--accent-color3);
      }
      // button {
      //   color: var(--accent-color1);
      //   background-color: var(--background);
      //   font-weight: bold;
      //   padding: 1rem 3rem;
      //   border-radius: 2rem;
      //   box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
      //   border: solid 3px transparent;
      //   background-image: linear-gradient(
      //       rgba(255, 255, 255, 0),
      //       rgba(255, 255, 255, 0)
      //     ),
      //     linear-gradient(101deg, var(--pink), var(--orange));
      //   background-origin: border-box;
      //   background-clip: content-box, border-box;
      //   box-shadow: 2px 1000px 1px var(--background) inset;
      //   transition: 0.5s ease-in-out;
      //   cursor: pointer;
      //   &:hover {
      //     box-shadow: none;
      //   }
      // }
    }
    .carousel-container {
      width: 100%;
      max-width: 700px; /* Adjust the maximum width as needed */
      margin: 0 auto;
    }
    
    .carousel {
      position: relative;
    }
    .carousel .slide {
      height: 700px; /* Adjust the height as needed */
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .carousel .slide img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
      border-radius: 28px; /* Add a border radius for a rounded appearance */
    }
    
    .carousel .control-arrow {
      font-size: 2rem;
      background: transparent;
      color: #ffffff;
      opacity: 0.8;
      transition: opacity 0.2s ease;
    }
    
    .carousel .control-arrow:hover {
      opacity: 1;
    }
    
    .carousel .control-dots {
      position: absolute;
      bottom: 20px;
      text-align: center;
      width: 100%;
    }
    
    .carousel .control-dots .dot {
      display: inline-block;
      width: 8px;
      height: 8px;
      margin: 0 4px;
      background: #ffffff9d;
      border-radius: 50%;
      cursor: pointer;
      opacity: 0.5;
      transition: opacity 0.2s ease;
    }
    
    .carousel .control-dots .dot.selected {
      opacity: 1;
    }
    
    .carousel .control-dots .dot:hover {
      opacity: 1;
    }
    .carousel .thumb.selected{
      border: 3px solid white;
      cursor: pointer;
  }
  .carousel .thumb {
    border: 3px solid grey;
    cursor: pointer;
 
}

    .image-container {
      text-align: center;
      position: relative;
    
      z-index: 10;
      .image {
        img {
          border-radius: 5%;
          // width: 50%;
        }
      }
      // .ellipse-container {
      //   .ellipse {
      //     // z-index: -1;
      //     // position: absolute;
      //     // height: 15rem;
      //     // width: 15rem;
      //     // filter: blur(100px);
      //   }
      //   .pink {
      //     top: 40%;
      //     right: 40%;
      //     background-color: var(--pink);
      //   }
      //   .orange {
      //     bottom: 40%;
      //     left: 40%;
      //     background-color: var(--orange);
      //   }
      // }
    }
  }
}
@keyframes fade-in {
   100% {opacity: 1;}
  }
// @media (min-width: 1067px) and (max-width: 1400px) {
//   .lore {
//   margin-top: 5rem;
// }}
// @media (min-width: 1701px) and (max-width: 2050px) {
//   .lore {
//   margin-top: 25rem;
// }}
// @media (min-width: 2051px) and (max-width: 2300px) {
//   .lore {
//   margin-top: 35rem;
// }}
// @media (min-width: 769px) and (max-width: 1066px) {
//   .lore {
//     margin-top: 2rem;
// }
// }
// @media screen and (min-width: 280px) and (max-width: 1080px) {
//   .lore {
//     padding: 1rem;
//     position: relative;
//     overflow-x: hidden;
//     margin-top: 1rem;
//     top:22rem;
//     .container {
//       font-size: 16px;
//       grid-template-columns: 0.7fr;
//       .image-container {
//         position: absolute;
//         right: -5%;
//         top: 30%;
//         .image {
//           img {
//             height: 2rem;
//           }
//         }
//         .ellipse-container {
//           .ellipse {
//             height: 6rem;
//             width: 6rem;
//             filter: blur(30px);
//           }
//           .pink {
//             right: 10%;
//           }
//           .orange {
//             left: -5%;
//           }
//         }
//       }
//     }
//   }
// }
// @media screen and (max-width: 280px) {
//   .lore {
//     overflow-x: hidden;
//     .container {
//       .content {
//         margin-top: 0;
//       }
//     }
//   }
// }

// @media screen and (min-width: 280px) and (max-width: 480px) {
//   .lore {
//     overflow-x: hidden;
//     width: 100%; /* Adjust the width based on your design */

//     margin-top: 0;
//     border-radius: 1%;

//     .container {
//       font-size: 16px;
//       height: auto; /* Set height to auto for responsiveness */

//       .content {
//         margin-top: 2rem;

//         .title {
//           font-size: 1.5rem;
//         }
//       }

//       .carousel-container {
//         margin-top: -5rem;
//         width: 100%;
//       }

//       .carousel .control-dots {
//         position: absolute;
//         bottom: 100px;
//         text-align: center;
//         width: 100%;
//       }
//     }
//   }
// }
// @media (max-width: 769px) {

  
//     .container {
//       font-size: 16px;
//       grid-template-columns: 1fr;
//       height: 50vh;
      
//       .content {
//         // margin-top: 15rem;
        
//         .sub-title {
//           font-size: 1.5rem;
//         }
//       }
//     }
//   }     
@media screen and (max-width: 769px) {
  .lore{

    display: none;
    
  }
 
  
  .mobile-only {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Adjust the minmax value for the column width */
    gap: 10px; /* Adjust the gap between images */
    padding: 10px; /* Adjust padding if needed */

  }

  .image-cont {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 8px; /* Optional: Add border-radius for rounded corners */
  }

  .image-cont img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Maintain aspect ratio and cover the entire container */
  }
  /* Add this to your existing CSS */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal {
  width: 80%; /* Set a fixed width */
  height: 80%; /* Set a fixed height */
  max-width: 600px; /* Optionally, you can set a maximum width */
  max-height: 600px; /* Optionally, you can set a maximum height */
}

.modal img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Adjust the object-fit property as needed */
}
/* Add this to your existing CSS */

.chronicle-title {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: white;
}
.title-mobile {
  display: block;
  text-align: center;
  margin-bottom: 2rem;
  color: white;
  margin-top: 3rem;
  border-top: 0.5rem solid purple;
    border-radius: 2rem;
}
.tmb {
  margin-top: 2rem;
}
}
