.card {
  background-color: var(--card-color);
  width: max-content;
  border-radius: 1rem;
  &-image {
    img {
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    .card-heading {
      display: flex;
      justify-content: space-between;
      .card-name {
        color: var(--orange);
        text-transform: uppercase;
        font-size: 0.7rem;
      }
      .card-top {
        color: var(--accent-color3);
        text-transform: uppercase;
        font-size: 0.7rem;
        text-align: right;

      }
    }
    .card-details {
      display: flex;
      
      justify-content: space-between;
      color: var(--accent-color1);
      .card-title {
      }
      .card-department {
        display: flex;
        gap: 1rem;
        text-align: right;
        img {
        }
        h4 {
        }
      }
    }
    .card-sub-details {
      display: flex;
      justify-content: space-between;
      span {
        color: var(--accent-color3);
        font-size: 0.9rem;
      }
    }
  }
}
