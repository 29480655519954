.roadmap {
    // border-top: 0.1rem solid white;
    // border-bottom: 0.1rem solid white;
    overflow: hidden;
    overflow-y: hidden;
    border-top: 0.5rem solid purple;
    border-radius: 2rem; 
    border-top-style:ridge;
    margin-top: 5rem;
    opacity: 0;
  animation: fade-in linear forwards;
  animation-timeline: view();
  animation-range-start: cover;
  animation-range-end: contain;
    .roadmap-cont {
      
    //   display: grid;
    //   // grid-template-columns: 1fr 1fr;
    //   font-size: 18px;
    display: flex;
 align-items: stretch;
//  gap: 50px;
 margin-top: 60px;
 

      
      .roadmap-content {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 1rem;
        align-items:center;
        width: 85%;
        justify-content: center;

        .sub-title1 {
          color: var(--pink);
          text-transform: uppercase;
          letter-spacing: 0.4rem;
          text-align: center;
        }
        .title1 {
          color: var(--accent-color1);
          font-size: 17px;
          font-weight: lighter;
          text-align:center;
          // font-family: cursive;
          
          text-decoration-line:overline;
          letter-spacing: 0.2rem;
          line-height: 2;
        }
        .description {
          color: var(--accent-color3);
        }
      
      }
      .image-container1 {
        text-align: center;
        position: relative;
      
        z-index: 10;
        .image1 {
          img {
            border-radius: 5%;
            width: 90%;
            
           
        
      }
    
    
    }
 
        .ellipse-container {
          .ellipse {
            z-index: -1;
            position: absolute;
            height: 15rem;
            width: 15rem;
            filter: blur(100px);
          }
          .pink {
            top: 40%;
            right: 40%;
            background-color: var(--pink);
          }
          .orange {
            bottom: 40%;
            left: 40%;
            background-color: var(--orange);
          }
        }
      }
    }
  }
  @keyframes fade-in {
    50%, 100% {opacity: 1;}
   }

   @media screen and (max-width: 1068px) {
    .roadmap{
      .roadmap-cont{
    flex-direction: column;
    gap: 3rem;
    align-items: center;
    .image-container1 {
      .image1 {
        img {
          border-radius: 5%;
          width: 70%;
        }
      }
    }
    // margin-top: 0;
    }
  }}