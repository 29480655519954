.super-rare {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 4rem 0;
  border-top: 0.5rem solid purple;
  border-radius: 2rem;
  border-top-style:ridge;
font-family: 'Times New Roman', Times, serif;
opacity: 0;
animation: fade-in linear forwards;
animation-timeline: view(150px);
animation-range-start: cover;
animation-range-end: contain;

  
  .title-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 5rem;
    .title {
      color: var(--accent-color1);
      font-size: 2.4rem;
      text-align: center;
      font-family: var(--font-family);
    }
    .description {
      color: var(--accent-color3);
      text-align: center;
      a {
        color: var(--accent-color3);
        font-weight: bold;
      }
    }
  }
  .cards {
    display: flex;
    justify-content: space-evenly;
    gap: 5rem;
    flex-wrap: wrap;
   
    
  }
  .card {
    width:33.3vh;
    height: 100%;

  }
  // changes by me
  img[alt="super1"], img[alt="super2"], img[alt="super3"] {
    width:100%;
    box-sizing: border-box;
    border: 5px rgba(18, 2, 2, 0) solid;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
    animation: float 3s ease-in-out infinite;
    // img { width: 100%; height: auto; }
  }
}
.twitter-icon {
  font-size: 28px; /* Adjust the size as needed */
  color: rgb(77, 50, 150);
}
.discord-icon {
  font-size: 31px; /* Adjust the size as needed */
  color: rgb(77, 50, 150);
}
@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(10px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(255, 251, 251, 0.2);
    transform: translatey(0px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(10px);
  }
}
@keyframes float2 {
  0% {
    transform: translatey(10px);
  }
  50% {
    transform: translatey(0px);
  }
  100% {
    transform: translatey(10px);
  }
}
@keyframes fade-in {
 100% {opacity: 1;}
 }
// @media screen and (min-width: 280px) and (max-width: 1080px) {
//   .super-rare {
//     border-radius: 1%;
//     position: relative;
//     padding: 1rem 2rem;
//     width: 100vw;
//     // top:42rem;
//     .cards {
//       overflow-x:auto;
//       justify-content: flex-start;
//       gap: 2rem;
//       padding-left: 1.5rem;
//       // &::-webkit-scrollbar {
//       //   display: none;
        
//       // }
//       .card {
//         zoom: 0.7;
//       }
//     }
//   }
// }
// @media screen and (min-width: 280px) and (max-width: 1080px) {
//   .super-rare {
//     border-radius: 1%;
//     position: relative;
//     padding: 1rem 2rem;
//     width: 100vw;
//     // top:42rem;
//     .cards {
//       display: flex;
//       flex-wrap: wrap;
//       justify-content: center;
//       gap: 2rem;
//       padding: 0;
//      font-size: small;
//         .card {
//         width: 80%; /* Cards occupy full width on mobile */
//         zoom: 1;
//       }
//       img[alt="super1"], img[alt="super2"], img[alt="super3"] {
//   width: 100%;
// }


//     }
//   }
// }
// @media (min-width: 1081px) and (max-width: 1400px) {

// .super-rare {
// // margin-left: 7rem;

// .cards{
// margin-left: 7rem;

//   width: 70vw;
//   gap:0;
//    .card{ 
    
    

// }}
// }
// }