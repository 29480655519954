// nav {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   .brand-container {
//     .brand {
//       img {
//         width: 35%;
//         position:relative;
//         padding-left: 5%;
//     margin-top: -3%;
//     margin-bottom: -3.5%;
//       }
//     }
//     .toggle-container {
//       display: none;
//       .toggle {
//       }
//       .mode {
//       }
//     }
//   }
//   .links-container {
//     .links {
//       padding-right: 5rem;
//       padding-bottom: 3rem;
//       list-style-type: none;
//       display: flex;
//       gap: 4rem;
//       text-align: center;
//       white-space: nowrap;
//             // font-family: cursive;

      
//       li {
//         .dark {
//           color: black;
//         }
//         .light {
//           color: yellow;
//         }
//         a {
//           color: var(--accent-color3);
//           text-decoration: none;
//         }
//         &:last-of-type {
          
//             color: grayscale($color: #3f4945ec);
    
//         }
//       }
//     }
//   }
// }

// // @media screen and (min-width: 280px) and (max-width: 650px) {
// //   nav {
// //     position: relative;
// //     padding: 1rem 2rem;
// //     margin-bottom: -20%;
// //     z-index: 9999;
// //     .brand-container {
// //       display: flex;
// //       justify-content: space-between;
// //       align-items:flex-start;
// //       width: 100%;
// //       .brand {
// //         img {

// //     width: 100%;
// //     object-fit: cover;
// //           height: 10.5rem;
// //           margin-left: -5%;
// //           margin-top:-10%;
// //         }
// //       }
// //       .toggle {
// //         margin-top: 2rem;
// //       }
// //       .toggle-container {
// //         display: block;
// //         color: var(--accent-color1);
// //         display: flex;
// //         flex-direction: row-reverse;
// //         gap: 1rem;
// //         z-index: 40;
// //         .toggle {
// //           z-index: 40;
// //           display: block;
// //         }
// //       }
// //     }
// //     .links-container {
      
// //       background-image: linear-gradient(101deg, rgb(192, 174, 192), var(--accent-color3));
// //      margin-top: -65%;
// //       // position: absolute;
// //       top: 0;
// //       right: 0;
// //       opacity: 0;
// //       width: 0;
// //       visibility: hidden;
// //       display: flex;
// //       justify-content: center;
// //       align-items: center;
// //       transition: 0.5s ease-in-out;
// //       font-size: small;
// //       .links {
// //         flex-direction: column;
// //         padding: 0 10px;
// //         li {
// //           a {
// //             color: black;
// //             font-weight:bold;
// //             // font-family: 'Courier New', Courier, monospace;
// //           }
// //           &:last-of-type {
// //             // display: none;
// //             color: grayscale($color: #3f4945ec);
// //             // font-family: 'Courier New', Courier, monospace;
// //             ;
// //           }
// //         }
// //       }
// //     }
// //     .nav-visible {
// //       width: 45vw;
// //       height: 120vw;
// //       visibility: visible;
// //       opacity: 1;
// //       float: right;
// //       position: absolute;
// //       .links{
// //         position: relative;
// //         margin-top: 15rem;
// //         gap: 5vw;
// //        }
      
// //     }
// //   }
// // }
// @media (max-width: 768px) {
//   nav {
//     position: relative;
//     padding: 1rem 2rem;
//     // margin-bottom: -20%;
//     z-index: 9999;

//     .brand-container {
//       display: flex;
//     justify-content: space-between;
//     align-items: flex-start;
//     width: 90%;
//       .brand {
//         // align-self: center;
//         img {
//           position:relative;
         
          

//     width: 60%;
//     // object-fit: cover;
//     //       height: 26.5rem;
//     //       margin-left: -5%;
//     //       margin-top:-10%;
//         }
//       }
//       .toggle {
//         margin-top: 2rem;
//         margin-right: -4rem;
//       }
//       .toggle-container {
//         display: block;
//         color: var(--accent-color1);
//         display: flex;
//         flex-direction: row-reverse;
//         gap: 1rem;
//         z-index: 40;

//         .toggle {
//           z-index: 40;
//           display: block;
//         }
//       }
//     }
//     .links-container {
      
//       z-index: 30;
//       background-image: linear-gradient(101deg, rgb(192, 174, 192), var(--accent-color3));
//      margin-top: -75%;
//       // position: absolute;
//       top: 0;
//       right: 0;
//       opacity: 0;
//       width: 0;
//       visibility: hidden;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       transition: 0.5s ease-in-out;
      
//       .links {
//         flex-direction: column;
//         padding: 0 10px;
//         li {
//           a {
//             color: black;
//             font-weight:bold;
//             // font-family: 'Courier New', Courier, monospace;
//           }
//           &:last-of-type {
//             // display: none;
//             color: grayscale($color: #3f4945ec);
//             // font-family: 'Courier New', Courier, monospace;
//             ;
//           }
//         }
//       }
//     }
//     .nav-visible {
//       width: 30vw;
//       height: 98vw;
//       visibility: visible;
//       opacity: 1;
//       float: right;
//       position: absolute;
//        .links{
//         margin-top: 55vw;
//         gap: 0;
//         height: 0vw;
//        }
      
//     }
//   }
// }
// @media (min-width: 769px) and (max-width: 1080px) {
//   nav {
//     z-index: 9999;
//     height: 200px;
//   }

//   nav .brand-container {
//     display: flex;
//     justify-content: space-between;
//     align-items: flex-start;
//     width: 100%;
    
//   }

//   nav .brand-container .brand {
//     img {
//       width: 100%;
//     }
//   }
// }
// @media (min-width: 1081px) and (max-width: 1400px) {
//   nav {
//     z-index: 9999;
//   }
//   nav .brand-container {
//     display: flex;
//     justify-content: space-between;
//     align-items: flex-start;
//     width: 100%;
    
//   }
//   nav .brand-container .brand {
//     img {
//       width: 70%; /* Adjust the width value as needed */
//     }
//   }
// }

nav {
  display: flex;
  justify-content: space-between;
  margin-top:0;
  margin-inline: 6vh;


}
.links-container {
  display:flex;
  align-items: flex-start;
  margin-top: 2vh;
  width: auto;
  z-index: 9999;


}
.links {
  display: flex;
  gap: 4vh;
  font-size: 1.3rem;
  list-style-type: none;
  
  @media (max-width: 940px) {
    // flex-direction: column;
    // gap: 2vh
    display:none

  }
}
.link {
  border: 1px solid grey;
  border-radius: 1.5rem;
  padding-inline: 8px;
  padding-block: 4px;
  transition: all 1s;
  // display: flex;
  // flex-wrap: nowrap;


}
.link:hover{
// color: red;
background-color: red;
}

.brand {
  display: flex;
  align-items: flex-end;
  z-index: 9999;

  @media (max-width: 940px) {
  // width: 30vh;
  // margin-top: -20px;
  margin: 0 auto;
  img {
    width: 100%;
    object-fit: contain;
  }
}
}