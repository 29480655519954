.signup {
  // border-top: 0.1rem solid white;
  // border-bottom: 0.1rem solid white;
  height: 90vh;
  overflow-y: hidden;
  
  margin: 0 auto;
 margin-bottom: 2rem;
  .container {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    height: 100%;
    min-height: 100vh;
    // gap: 2rem;
    // padding: 2rem;
    // position: absolute;
    left:0;
    right: 0;
 
  }

 


    .content {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items:center;
      font-family: 'Times New Roman', Times, serif;
      text-align: center;
      animation: float2 3s ease-in-out infinite;
      font-size: medium;
      height: 100%;



      .sub-title {
        color: var(--pink);
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-size: medium;
        // animation: float2 3s ease-in-out infinite;
      }
      .title {
        color: var(--accent-color1);
        @media (min-width: 268px) {
         text-align: center;
         font-size: larger;
        //  animation: float2 5s ease-in-out infinite;
        }
      }
      .description {
        color: var(--accent-color3);
        opacity: 1;
        
      
      }
      
      button {
        // animation: float2 5s ease-in-out infinite;
        color: var(--accent-color1);
        background-color: var(--background);
        font-weight: bold;
        font-family: var(--font-family);
        padding: 1rem 3rem;
        border-radius: 2rem;
        box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
        border: solid 3px transparent;
        background-image: linear-gradient(
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0)
          ),
          linear-gradient(101deg, var(--pink), var(--orange));
        background-origin: border-box;
        background-clip: content-box, border-box;
        box-shadow: 2px 1000px 1px var(--background) inset;
        transition: 0.5s ease-in-out;
        font-family: 'Times New Roman', Times, serif;

        cursor: pointer;
        &:hover {
          box-shadow: none;
        }
      }
      
    }
  }


@media  (max-width: 769px) {
  .signup{
        height: 80%;
        margin-bottom: 5rem;
        margin-top: -4rem;
      }}