.like {
      border-top: 0.5rem solid purple;
    border-radius: 2rem; 
    border-top-style:ridge;
    margin-bottom: 2rem;
    // margin-top: 5rem;
    opacity: 0;
animation: fade-in linear forwards;
animation-timeline: view(150px);
animation-range-start: cover;
animation-range-end: contain;

  // margin: 5rem 0;
  .container {
    margin-top: 4rem;
    // display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    padding: 4rem;
    .content {
      background-color: rgb(104, 5, 104);       //var(--accent-color2);
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding: 3rem;
      border-radius: 2rem;
      .image {
        img {
        }
      }
      .title {
        
        color: var(--accent-color1);
      }
      .faq-question {
        cursor: pointer;
        color: var(--accent-color3);
        letter-spacing: 0.1rem;
        line-height: 1.5rem;
        
      }
      // .faq-answer {
      //   max-height: 0;
      //   overflow: hidden;
      //   transition: max-height 0.3s ease;
      // }
      .faq-answer {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease;
        font-family:'Times New Roman', Times, serif;
      
      }
      .last-question {
        color:rgb(104, 5, 104);
      }
      .last-answer {
        color: purple;
        font-family: var(--font-family);
        font-size: xx-small;
      }
      // .faq-question.active .faq-answer {
      //   max-height: 500px; /* Adjust this value based on your content height */
      //   transition: max-height 0.3s ease;
      // }

      .view-certificate-button {
        display: inline-block;
        padding: 2px 6px;
        background-color: #f2f2f28a;
        color: #333333;
        text-decoration: none;
        border: none;
        border-radius: 20px;
        font-size: 12px;
        cursor: pointer;
        transition: background-color 0.9s ease;
      }
      
      .view-certificate-button:hover {
        background-color: rgba(150, 127, 127, 0.458);
        color: white;
        transition: color 0.9s ease;
      }
    }
  }
}
@keyframes fade-in {
  100% {opacity: 1;}
  }
@media screen and (min-width: 280px) and (max-width: 1080px) {
  .like {
    margin: 0;
    border-radius: 2rem;
    position: relative;
    // top:22rem;
    .container {
      grid-template-columns: 1fr;
      gap: 0;
      .content {
        padding: 1rem 2rem;
        border-radius: 2rem;
        &:nth-of-type(2) {
          background-color: var(--background);
        }
      }
    }
  }
}
