.clients {
  // padding: 6rem 0;
  .container {
    // .clients-container {
    //   display: grid;
    //   grid-template-columns: repeat(2, 1fr);
    //   text-align: center;
    //   align-items: baseline;
    

    //   .client {
    //     img {
    //       width: 20%;
    //     }
    //   }
    // }
    .clients-container {
      display: flex;
      justify-content: center; /* Align items horizontally in the center */
      align-items: center; /* Align items vertically in the center */
      
    }
    
    .client {
      margin: 0 20px; /* Adjust the margin as per your preference */
    }
    
    .client img  {
      max-width: 100%;
      max-height: 100px; /* Adjust the maximum height as needed */

    }
    .client.first-client img {
      max-width: 100%;
      max-height: 40px; /* Adjust the maximum height as needed */
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    padding: 1rem 2rem;
    position: relative;
    height: 10rem;
    top:2rem;   
    .container {
      .clients-container {
        grid-template-columns: repeat(2, 1fr);
        text-align: left;
        gap: 1rem;
      

        .client {
          &:nth-of-type(5) {
            display: none;
          }
          img {
            // height: 5rem;
            // width: 5rem;
            
          }
        }
      }
    }
  }
}
